import Model from './Model'
import { DateTime } from 'luxon';

export default class PlanningLine extends Model {
    duration() {
        return this.to.diff(this.from, ['hours', 'minutes']);
    }

    weekNumber() {
        return this.from.toFormat('WW');
    }
}
