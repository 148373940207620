<template>
    <tr :class="{'bg-fuel-yellow--lighter': error}">

        <td>
            <div v-if="readonly">
                Du <span v-text="line.from.toFormat('DDDD')"></span> - <strong v-text="line.from.toFormat('t')"></strong>
            </div>
            <div v-else class="input-group">
                <div class="input-group-prepend">
                    <div class="input-group-text">Du</div>
                </div>
                <input type="date" :disabled="disabled" @blur="$emit('lineUpdated', JSON.stringify(line))" :value="line.from && line.from.toISODate()" @input="setFromDate($event)" class="form-control" :class="{'is-invalid': error }">
                <input type="time" :disabled="disabled" @blur="$emit('lineUpdated', JSON.stringify(line))" :value="line.from && line.from.toISOTime({includeOffset: false, suppressMilliseconds: true, extendedZone: false, suppressSeconds: true})" @input="setFromTime($event)" class="form-control" :class="{'is-invalid': error }">
                <div class="invalid-feedback" v-if="error == 'invalid-from'">Date invalide</div>
                <div class="invalid-feedback" v-else-if="error == 'invalid-interval'">Plage horaire invalide</div>
                <div class="invalid-feedback" v-else-if="error == 'overlapping'">Conflit entre plusieurs plages horaires</div>
            </div>
        </td>
        <td>
            <div v-if="readonly">
                Au <span v-text="line.to.toFormat('DDDD')"></span> - <strong v-text="line.to.toFormat('t')"></strong>
            </div>
            <div v-else class="input-group">
                <div class="input-group-prepend">
                    <div class="input-group-text">Au</div>
                </div>
                <input type="date" :disabled="disabled" @blur="$emit('lineUpdated', JSON.stringify(line))" :value="line.to.toISODate()" @input="setToDate($event)" class="form-control" :min="line.from.date" :class="{'is-invalid': error }">
                <input type="time" :disabled="disabled" @blur="$emit('lineUpdated', JSON.stringify(line))" :value="line.to.toISOTime({includeOffset: false, suppressMilliseconds: true, extendedZone: false, suppressSeconds: true})" @input="setToTime($event)" class="form-control" :class="{'is-invalid': error }">
                <div class="invalid-feedback" v-if="error == 'invalid-to'">Date invalide</div>
            </div>
        </td>
        <td>{{ line.duration().hours }}h{{ String(line.duration().minutes).padStart(2, '0') }}m</td>
        <td v-show="!disabled" class="text-right">
            <button type="button" class="btn" :class="{'active': !readonly}" title="Modifier" @click="readonly = !readonly">
                <i class="fa-solid color-fuel-yellow" :class="{'fa-pencil': readonly, 'fa-save': !readonly}"></i>
            </button>
            <button type="button" class="btn" title="Dupliquer le jour suivant" @click="$emit('duplicateToNextDay')">
                <i class="fa-solid fa-arrow-turn-down color-fuel-yellow"></i>
            </button>
            <button type="button" title="Supprimer" class="btn" @click="$emit('dropLine')">
                <i class="fa-solid fa-trash color-dark-raspberry h5"></i>
            </button>
        </td>
    </tr>
</template>

<script>
    import { DateTime, Interval } from 'luxon';

    export default {
        name: "PlanningLine",
        data() {
            return {
                readonly: true,
            };
        },
        props: {
            disabled: {
                type: Boolean,
                default: false,
            },
            line: {
                type: Object,
                default() {
                    return {
                        from: null,
                        to: null,
                        selected: false,
                    };
                }
            },
        },
        computed: {
            error() {
                if (!this.line.from && !this.line.from.isValid) {
                    return 'invalid-from';
                }

                if (!this.line.to && !this.line.to.isValid) {
                    return 'invalid-to';
                }

                let interval = Interval.fromDateTimes(this.line.from, this.line.to);

                if (!interval.isValid || interval.length('seconds') == 0) {
                    return 'invalid-interval';
                }

                let overlapping = this.$parent
                    .timetable
                    .filter(function (line) {
                        let lineInterval = Interval.fromDateTimes(line.from, line.to);

                        return interval.overlaps(lineInterval);
                    })

                if (overlapping.length > 1) {
                    return 'overlapping';
                }

                return null;
            },
        },
        methods: {
            setFromDate($event) {
                let date = $event.target.value.split('-');

                this.line.from = this.line.from.set({
                    year: date[0],
                    month: date[1],
                    day: date[2],
                });
            },
            setFromTime($event) {
                let date = $event.target.value.split(':');

                this.line.from = this.line.from.set({
                    hours: date[0],
                    minutes: date[1],
                });
            },
            setToDate($event) {
                let date = $event.target.value.split('-');

                this.line.to = this.line.to.set({
                    year: date[0],
                    month: date[1],
                    day: date[2],
                });
            },
            setToTime($event) {
                let date = $event.target.value.split(':');

                this.line.to = this.line.to.set({
                    hours: date[0],
                    minutes: date[1],
                });
            },
        }
    }
</script>
