<template>
    <div>
        <form @submit.prevent="batchAddLines()" v-if="!disabled">
            <div class="row mb-3">
                <div class="col-12" v-if="schedules.length">
                    <div class="form-inline">
                        <label for="Schedule">Horaire type :</label>
                        <select id="Schedule" class="form-control ml-3" v-model="schedule" @change="selectSchedule">
                            <option></option>
                            <option :value="schedule" v-for="schedule in schedules">{{ schedule.name }}</option>
                        </select>
                    </div>
                </div>
                <div class="col-12">
                    <div class="form-inline">
                        <label>Jours travaillés : </label>
                        <div class="form-check m-3" v-for="(day, index) in weekdays">
                            <input type="checkbox" class="form-check-input" v-model="tempBatch.weekdays[index]" :id="'Weekday-' + index">
                            <label class="form-check-label" :for="'Weekday-' + index">{{ day }}</label>
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <div class="form-inline">
                        <div class="d-flex m-3">
                            <input type="radio" id="continuous" value="continuous" v-model="timeSlot" class="mr-1">
                            <label for="continuous">Horaires continus</label>
                        </div>
                        <div class="d-flex m-3">
                            <input type="radio" id="discontinuous" value="discontinuous" v-model="timeSlot" class="mr-1">
                            <label for="discontinuous">Horaires discontinus</label>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 mb-3" :class="timeSlot === 'discontinuous' ? 'col-xl-2' : 'col-lg-3'">
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <div class="input-group-text">Du&nbsp;<span title="Requis">*</span></div>
                        </div>
                        <input type="date" required v-model="tempBatch.from" @change="setTempBatchTo()" class="form-control">
                    </div>
                </div>
                <div class="col-md-6 mb-3" :class="timeSlot === 'discontinuous' ? 'col-xl-2' : 'col-lg-3'">
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <div class="input-group-text">Au&nbsp;<span title="Requis">*</span></div>
                        </div>
                        <input type="date" required v-model="tempBatch.to" :min="tempBatch.from" class="form-control">
                    </div>
                </div>
                <div class="col-md-6 mb-3" :class="timeSlot === 'discontinuous' ? 'col-xl-3' : 'col-lg-4'">
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <div class="input-group-text">Plage horaire<span v-if="timeSlot == 'discontinuous'">&nbsp;1</span>&nbsp;<span title="Requis">*</span></div>
                        </div>
                        <input type="time" required v-model="tempBatch.beginAtAM" class="form-control" @change="tempBatch.endAtAM = tempBatch.beginAtAM">
                        <input type="time" required v-model="tempBatch.endAtAM" class="form-control">
                    </div>
                    <small class="form-text text-muted" v-show="tempBatch.beginAtAM > tempBatch.endAtAM"><span class="color-fuel-yellow">Attention</span> : cette plage horaire se terminera le jour suivant</small>
                </div>
                <div class="col-xl-3 col-md-6 mb-3" v-if="timeSlot == 'discontinuous'">
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <div class="input-group-text">Plage horaire 2</div>
                        </div>
                        <input type="time" v-model="tempBatch.beginAtPM" class="form-control" @change="tempBatch.endAtPM = tempBatch.beginAtPM">
                        <input type="time" v-model="tempBatch.endAtPM" class="form-control">
                    </div>
                    <small class="form-text text-muted" v-show="tempBatch.beginAtPM > tempBatch.endAtPM"><span class="color-fuel-yellow">Attention</span> : cette plage horaire se terminera le jour suivant</small>
                </div>
                <div class="col-md-2 text-center text-md-left" :class="timeSlot === 'discontinuous' ? 'mx-auto' : ''">
                    <button class="btn p-0 btn-no-spinner" type="submit" :disabled="formIsDisabled">
                        <i title="Ajouter un horaire" class="fa-solid fa-circle-plus fa-2x color-blue-calypso"></i>
                    </button>
                </div>
            </div>
        </form>

        <div class="table-responsive">
            <table class="table table-bordered overflow-auto">
                <thead v-show="!disabled">
                    <tr>
                        <th colspan="5" class="text-right">
                            <button type="button" class="btn btn-blue-calypso" :class="{'disabled': !timetable.length}" @click.prevent="dropAllLines">
                                Tout supprimer
                            </button>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <template v-for="(line, index) in timetable">
                        <planning-line-component
                            :key="index"
                            :line="line"
                            @lineUpdated="onLineUpdated"
                            @dropLine="dropLine(index)"
                            @duplicateToNextDay="duplicateToNextDay(index)"
                            :disabled="disabled"
                        ></planning-line-component>
                        <tr v-show="index + 1 == timetable.length || line.weekNumber() != timetable[index+1].weekNumber()" class="bg-fuel-yellow--lighter">
                            <td>Semaine {{ line.weekNumber() }}</td>
                            <td colspan="4">
                                <span v-text="planningDurationPerWeek[line.weekNumber()].hours" />h<span v-text="String(planningDurationPerWeek[line.weekNumber()].minutes).padStart(2, '0')" />m
                            </td>
                        </tr>
                    </template>
                    <tr v-if="timetable.length == 0">
                        <td></td>
                        <td class="text-center" colspan="4">Aucun horaire renseigné </td>
                    </tr>
                    <tr>
                        <td><strong>Total</strong></td>
                        <td colspan="5"><span v-text="planningDuration.hours" />h<span v-text="String(planningDuration.minutes).padStart(2, '0')" />m</td>
                    </tr>
                </tbody>
            </table>
            <input type="hidden" :name="getInputName()" :value="JSON.stringify(timetable)">
        </div>
    </div>
</template>

<script>
    import { DateTime, Duration, Info, Interval } from 'luxon';
    import PlanningLineComponent from './PlanningLine.vue';
    import { PlanningLine } from '@models';
    import { range } from 'lodash';

    export default {
        name: "Planning",
        components: {
            PlanningLineComponent
        },
        props: {
            initTimetable: {
                type: Array,
                default() {
                    return [];
                },
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            inputName: {
                type: String,
                default: 'planning_lines',
            },
            schedules: {
                type: Array,
                default() {
                    return [];
                },
            },
        },
        data() {
            return {
                tempBatch: this.initialBatchLine(),
                timetable: [],
                schedule: null,
                timeSlot: 'continuous',
            };
        },
        created() {
            this.timetable = PlanningLine.make(
                this.initTimetable.map((line) => {
                    return {
                        selected: false,
                        from: DateTime.fromISO(line.from),
                        to: DateTime.fromISO(line.to),
                    };
                })
            );
        },
        computed: {
            formIsDisabled() {
                return 0 === Object.values(this.tempBatch.weekdays).filter(value => value).length
            },
            selectedLines() {
                return this.timetable.filter((value, index) => { return value.selected});
            },
            planningDuration() {
                let _this = this;

                let duration = this.timetable
                    .reduce(function (carry, line) {
                        return carry.plus(line.duration());
                    }, Duration.fromObject({}))
                    .shiftTo('hours', 'minutes')
                    .toObject()
                ;

                return {
                    'hours': duration.hours,
                    'minutes': String(duration.minutes).padStart(2, '0')
                };
            },
            planningDurationPerWeek() {
                return this.timetable
                    .reduce(function (carry, line) {
                        let weekNumber = line.weekNumber();

                        if (weekNumber in carry) {
                            carry[weekNumber] = carry[weekNumber]
                                .plus(line.duration())
                                .shiftTo('hours', 'minutes')
                        } else {
                            carry[weekNumber] = line.duration()
                        }

                        return carry;
                    }, {})
            },
            weekdays() {
                return range(1, 8)
                    .reduce((acc, element) => {
                        return {
                            ...acc,
                            [element]: Info.weekdays('long')[element - 1]
                        }
                    }, {})
                ;
            }
        },
        methods: {
            addLineFromBatch(date, fromTime, toTime) {
                let endDate = (fromTime > toTime)
                    ? date.plus({ days: 1 })
                    : date
                ;

                fromTime = fromTime.split(':');
                toTime = toTime.split(':');

                this.addToTimetable({
                    from: date.set({
                        hours: fromTime[0],
                        minutes: fromTime[1],
                    }),
                    to: endDate.set({
                        hours: toTime[0],
                        minutes: toTime[1],
                    }),
                })
            },
            addToTimetable(line, index = null) {
                this.timetable.push(
                    new PlanningLine({
                        from: line.from,
                        to: line.to,
                        selected: false,
                    })
                );

                this.sortTimetable();
            },
            batchAddLines() {
                let startDate = DateTime.fromISO(this.tempBatch.from);
                let endDate = DateTime.fromISO(this.tempBatch.to);

                do {
                    if (this.tempBatch.weekdays[startDate.weekday]) {
                        this.addLineFromBatch(
                            startDate,
                            this.tempBatch.beginAtAM,
                            this.tempBatch.endAtAM
                        );

                        if (
                            null !== this.tempBatch.beginAtPM
                                && null !== this.tempBatch.endAtPM
                        ) {
                            this.addLineFromBatch(
                                startDate,
                                this.tempBatch.beginAtPM,
                                this.tempBatch.endAtPM
                            );
                        }
                    }

                    startDate = startDate.plus({ day: 1 })
                } while (startDate <= endDate);

                this.resetBatchLine();
            },
            dropLine(index) {
                this.timetable.splice(index, 1);

                this.sortTimetable();
            },
            dropAllLines() {
                this.timetable.splice(0)

                this.sortTimetable();
            },
            duplicateToNextDay(index) {
                let initialLine = this.timetable[index];

                this.addToTimetable({
                    from: initialLine.from.plus({ day: 1}),
                    to: initialLine.to.plus({ day: 1}),
                });
            },
            getInputName() {
                return this.inputName;
            },
            initialBatchLine() {
                return {
                    from: null,
                    to: null,
                    beginAtAM: null,
                    endAtAM: null,
                    beginAtPM: null,
                    endAtPM: null,
                    weekdays: {
                        1: true,
                        2: true,
                        3: true,
                        4: true,
                        5: true,
                        6: true,
                        7: true,
                    }
                };
            },
            onLineUpdated() {
                this.sortTimetable();
            },
            resetBatchLine() {
                this.tempBatch.beginAtAM = null;
                this.tempBatch.endAtAM = null;
                this.tempBatch.beginAtPM = null;
                this.tempBatch.endAtPM = null;
            },
            selectSchedule() {
                if (this.schedule) {
                    this.tempBatch.beginAtAM = this.schedule.from_am;
                    this.tempBatch.endAtAM = this.schedule.to_am;

                    if (this.schedule.from_pm.length && this.schedule.to_pm.length) {
                        this.tempBatch.beginAtPM = this.schedule.from_pm;
                        this.tempBatch.endAtPM = this.schedule.to_pm;
                    }

                    this.tempBatch.weekdays = this.schedule.weekdays;
                } else {
                    this.resetBatchLine();
                }
            },
            setTempBatchTo() {
                this.tempBatch.to = this.tempBatch.from;
            },
            sortTimetable() {
                this.timetable.sort((a, b) => {
                    return a.from - b.from;
                })
            },
        },
        watch: {
        }
    }
</script>

<style lang="scss">
    .w-10 {
        width: 10%;
    }

    input[type='radio'] {
        accent-color: #148181;
    }
</style>
