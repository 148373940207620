<template>
    <div class="col-12 d-flex mb-5">
        <div class="col-12 h-100 box-shadow" :class="[context ? 'col-md-12' : 'col-md-6']">
            <vue-cal
                ref="calendar"
                class="vuecal--rounded-theme bg-white card shadow-none vuecal--full-height-delete cursor-pointer border-radius-bottom-0"
                :class="[context ? '' : 'h-500']"
                :selected-date="dateNow"
                xsmall
                showWeekNumbers
                locale="fr"
                :time-from="0"
                :disable-views="['years', 'year', 'week']"
                active-view="month"
                :events="events"
                :editable-events="{
                    title: false,
                    drag: false,
                    resize: false,
                    delete: true,
                    editable: false,
                }"
                events-on-month-views
                :dblclick-to-navigate="mobileScreen"
                @cell-click="selectDate"
                @view-change="getEvents"
                @event-delete="onEventDelete"
                :hide-view-selector="!mobileScreen || context"
                @event-mouse-enter="onEventFocus"
            >
                <template v-slot:arrow-prev>
                    <i class="fa-solid fa-chevron-left color-fuel-yellow"></i>
                </template>
                <template v-slot:arrow-next>
                    <i class="fa-solid fa-chevron-right color-fuel-yellow"></i>
                </template>
                <template #events-count="{ events }">
                    <span :class="events[0].class" class="vuecal__cell-events-count mt-3"></span>
                </template>
                <template v-if="weeklyHours" class="weekly-hours-column" #week-number-cell="{ week }">
                    <div class="week-number-cell align-items-end">
                        <div
                            :class="[weeklyHours[week].isOverMaxTime ? 'bg-fuel-yellow text-white' : 'bg-dugong--lighter']"
                            class="text-center w-100"
                            v-if="weeklyHours[week]">
                            {{ weeklyHours[week].time }}
                        </div>
                    </div>
                </template>
            </vue-cal>
            <div class="d-flex justify-content-center mt-n5">
                <button
                    v-show="selectedEvent && mobileScreen"
                    type="button"
                    class="btn btn-dark-raspberry rounded-circle p-2 btn-add border-white"
                    @click="onEventDelete()"
                >
                    <i class="fa-solid fa-trash"></i>
                </button>
            </div>
            <div class="card mt-5 shadow-none p-3 border-radius-top-0" v-if="!context">
                <div class="card mt-3 shadow-none p-3">
                    <div class="d-flex align-items-center">
                        <span class="h1 m-0 color-dark-raspberry pr-1">•</span>Indisponible
                    </div>
                    <div class="d-flex align-items-center">
                        <span class="h1 m-0 color-finger-banana pr-1">•</span>Indisponible en contrat
                        Ettic
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-6 d-none d-md-block box-shadow ml-3" v-if="!context">
            <vue-cal
                ref="dayCalendar"
                class="vuecal--rounded-theme bg-white card shadow-none vuecal--full-height-delete h-500"
                :selected-date="selectedDate"
                :disable-views="['years', 'year', 'month', 'week']"
                small
                locale="fr"
                :time-from="0"
                active-view="day"
                :events="events"
                hide-view-selector
                showAllDayEvents
                @view-change="getEvents"
                :editable-events="{ title: false, drag: false, delete: true, resize: false }"
                @event-delete="onEventDelete"
                @event-mouse-enter="onEventFocus"
            >
                <template v-slot:arrow-prev>
                    <i class="fa-solid fa-chevron-left color-fuel-yellow"></i>
                </template>
                <template v-slot:arrow-next>
                    <i class="fa-solid fa-chevron-right color-fuel-yellow"></i>
                </template>
            </vue-cal>
            <div class="d-flex justify-content-center mt-n5">
                <button
                    v-show="selectedEvent && canDelete"
                    type="submit"
                    class="btn btn-dark-raspberry rounded-circle p-2 btn-add border-white"
                    @click="onEventDelete()"
                >
                    <i class="fa-solid fa-trash"></i>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from "axios";
    import VueCal from "vue-cal";
    import "vue-cal/dist/vuecal.css";
    import { DateTime, Duration } from "luxon";

    export default {
        name: "Availabilities",
        components: { VueCal },
        data() {
            return {
                dateNow: DateTime.now().toJSDate(),
                selectedDate: DateTime.now().toJSDate(),
                events: [],
                mobileScreen: "",
                showDelete: false,
                selectedEvent: null,
                weeklyHours: {},
            };
        },

        props: {
            applicant: {
                type: Object,
                default() {
                    null;
                },
            },
            canDelete: {
                type: Boolean,
                default: false,
            },
            url: {
                type: String,
            },
            isAdmin: {
                type: Boolean,
                default: false,
            },
            context: {
                type: String,
                default() {
                    null;
                },
            },
        },
        methods: {
            getEvents() {
                this.selectedEvent = null;
                axios
                    .get(this.url, {
                        params: {
                            start: this.$refs.calendar.view.startDate,
                            end: this.$refs.calendar.view.endDate,
                        },
                    })
                    .then((response) => {
                        this.events = response.data;
                        this.weeklyHours = this.calculateWeeklyHours(this.events);
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            },
            selectDate(e) {
                this.selectedDate = e;
            },
            isMobile() {
                this.mobileScreen = screen.width <= 760;
            },
            onEventDelete() {
                let urlDelete;
                if (this.isAdmin) {
                    urlDelete = route("api.admin.applicant.availability.destroy", {
                        applicant: this.applicant,
                        availability: this.selectedEvent.id,
                    });
                } else {
                    urlDelete = route("api.applicant.availability.destroy", {
                        availability: this.selectedEvent.id,
                    });
                }

                if (this.selectedEvent.type !== "unavailable") {
                    alert("Vous ne pouvez pas supprimer une indisponibilité liée à un contrat");
                } else {
                    axios.delete(urlDelete).then(() => {
                        this.getEvents();
                        this.selectedEvent = null;
                    });
                }
            },
            onEventFocus(e) {
                if (e.type === "unavailable") {
                    this.selectedEvent = e;
                } else {
                    this.selectedEvent = null;
                }
            },
            calculateWeeklyHours(events) {
                const weeklyHours = {};
                this.events
                    .filter(event => event.type !== "unavailable")
                    .forEach((event) => {
                        let start = DateTime.fromFormat(event.start, 'yyyy-MM-dd HH:mm');
                        const end = DateTime.fromFormat(event.end, 'yyyy-MM-dd HH:mm');
                        const weekNumber = start.weekNumber;
                        if (!event.advanced_planning) {
                            weeklyHours[weekNumber] = "Planning non renseigné";
                            return;
                        }

                        if (weeklyHours[weekNumber] === "Planning non renseigné") {
                            return;
                        }
                        while (start < end) {
                            const endOfDay = end < start.endOf('day') ? end : start.endOf('day');
                            const duration = endOfDay.diff(start, ['hours', 'minutes']);
                            const roundedDuration = {
                                hours: duration.hours,
                                minutes: Math.round(duration.minutes)
                            };

                            weeklyHours[weekNumber] = (weeklyHours[weekNumber] || Duration.fromObject({ hours: 0, minutes: 0 })).plus(Duration.fromObject(roundedDuration));
                            start = start.plus({ days: 1 }).startOf('day');
                        }
                    });

                    Object.keys(weeklyHours).forEach(week => {
                        if (weeklyHours[week] === "Planning non renseigné") {
                            weeklyHours[week] = { time: "Planning non renseigné", isOverMaxTime: null };
                        } else {
                            const durationWeek = weeklyHours[week].shiftTo('hours', 'minutes');
                            if (durationWeek.minutes == 0) {
                                weeklyHours[week] = {
                                    time: `${durationWeek.hours}h00 prévues au planning`,
                                    isOverMaxTime: durationWeek.hours >= 35
                                };
                            } else {
                                weeklyHours[week] = {
                                    time: `${durationWeek.hours}h${durationWeek.minutes} prévues au planning`,
                                    isOverMaxTime: durationWeek.hours >= 35
                                };
                            }
                        }
                    });
                    return weeklyHours;
            },
        },
        mounted() {
            this.getEvents();
            this.isMobile();
        },
    };
</script>

<style>
    .border-radius-top-0 {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }

    .border-radius-bottom-0 {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    .vuecal--month-view .vuecal__bg {
        overflow: hidden;
    }

    .vuecal__cell-events-count {
        color: transparent !important;
    }

    .vuecal__cell-events-count span {
        width: 10px;
    }

    .vuecal__cell-events-count:not(span) {
        background-color: transparent;
        height: 10px;
        width: 10px;
    }

    .vuecal__menu {
        margin-bottom: 10px;
        background-color: white;
        @media screen and (max-width: 575px) {
            display: none;
        }
    }

    .vuecal__cell--today,
    .vuecal__cell--current {
        background-color: #aba3ae;
    }

    .vuecal:not(.vuecal--day-view) .vuecal__cell--selected .vuecal__cell-content {
        background-color: #fcf5e7;
        height: 25px;
        width: 25px;
    }

    .vuecal__cell--selected:before {
        border-color: #148181;
    }

    .weekday-label {
        font-weight: bold;
    }

    .vuecal__title-bar {
        background-color: transparent;
        padding-top: 10px;
    }

    .vuecal__cells.month-view .vuecal__cell {
        min-height: 60px;
    }

    .vuecal__event-time {
        display: none;
    }

    .vuecal__event-title,
    .vuecal__event {
        color: white;
    }

    .vuecal__now-line {
        color: #d48f31;
        z-index: 10;
    }

    .vuecal__event--focus,
    .vuecal__event:focus {
        box-shadow: 0px 0px 9px 2px #00000063;
    }

    #ProfileAvailabilities .box-shadow {
        box-shadow: 0 0 1.25rem rgba(0, 0, 0, 0.15);
        border-radius: 10px;
    }


    .vuecal__week-numbers{
        position: absolute;
        width: 100%;
        height: 100%;
    }

    .vuecal__week-number-cell{
        opacity: 1 !important;
        margin-top: 10px;
    }

    .week-number-cell{
        min-height: 2.5rem;

        display: flex;
        width: 100%;
        font-size: 0.7em;
        :is(div) {
            border-radius: 30px;
        }
    }

    .vuecal--week-numbers .vuecal__weekdays-headings{
        padding-left: 0px;
    }
</style>
