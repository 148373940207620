<template>
    <tr class="d-flex p-2 border-radius-20 flex-wrap">
        <div class="text-uppercase font-weight-bold text-center col-12 col-md-2 d-flex p-0">
            {{ line.from ? formatDayOfWeek(line.from) : '' }}
        </div>
        <div class="d-flex flex-column w-100 border-radius-20 col-12 col-md-10 p-0">
            <div v-if="differFromPlanningFrom || differFromPlanningTo" class="bg-dark-raspberry color-white text-center">Modifié</div>
            <div :class="{'bg-fuel-yellow--lighter': !error, 'bg-dark-raspberry--lighter': error}" class="p-2 rounded w-100">
                <div class="d-flex justify-content-between p-2 align-items-start">
                    <div class="cursor-pointer col-10" @click="editLine()">
                        <span :class="{'line-through': isDeleted || workleave}">
                        de
                        <strong :class="{'color-dark-raspberry': differFromPlanningFrom || isDeleted}">{{line.from && line.from.toISOTime({includeOffset: false, suppressMilliseconds: true, extendedZone: false, suppressSeconds: true})}}</strong>
                        </span>
                        <span :class="{'line-through': isDeleted || workleave}">
                        à
                        <strong :class="{'color-dark-raspberry': differFromPlanningTo || isDeleted}">{{line.to && line.to.toISOTime({includeOffset: false, suppressMilliseconds: true, extendedZone: false, suppressSeconds: true})}}</strong>
                        </span>
                        <strong v-show="line.code !== null">: {{ workleave }}</strong>
                    </div>
                    <div class="d-flex">
                        <button type="button" class="btn p-0" :class="{'active': !readonly}" title="Modifier" @click="editLine()" v-show="!disabled && !isDeleted && !error">
                            <i class="fa-solid color-fuel-yellow" :class="{'fa-pen': readonly, 'fa-save': !readonly && !error}"></i>
                        </button>
                        <button type="button" class="btn p-0 pr-2" :class="{'active': !readonly}" title="Ajouter une ligne" @click="$emit('duplicateToNextDay')" v-show="!disabled && !isDeleted && !error">
                            <i class="fa-solid fa-plus color-fuel-yellow"></i>
                        </button>
                        <button type="button" class="btn p-0" :class="{'d-none': !disabled && !isDeleted,'d-md-block': !disabled && !isDeleted, 'active': !readonly}" title="Supprimer la ligne" @click="deleteLine()" v-show="!disabled && !isDeleted || !readonly" v-if="readonly">
                            <i class="fa-solid fa-trash color-dark-raspberry"></i>
                        </button>
                    </div>
                </div>
                <div v-if="!readonly || error">
                    <div class="input-group d-flex flex-column flex-wrap">
                        <div class="d-flex flex-column col-12">
                            <label>Du :</label>
                            <div class="d-flex justify-content-between flex-wrap">
                                <input type="date" :disabled="disabled || line.code" @blur="$emit('lineUpdated', JSON.stringify(line))" :value="line.from && line.from.toISODate()" @input="setFromDate($event)" class="form-control col-md-5 col-12" :class="{'is-invalid': error }">
                                <input type="time" :disabled="disabled || line.code" @blur="$emit('lineUpdated', JSON.stringify(line))" :value="line.from && line.from.toISOTime({includeOffset: false, suppressMilliseconds: true, extendedZone: false, suppressSeconds: true})" @input="setFromTime($event)" class="form-control col-12 col-md-5" :class="{'is-invalid': error }">
                            </div>
                            <div class="invalid-feedback d-flex" v-if="error == 'invalid-from'">Date invalide</div>
                            <div class="invalid-feedback d-flex" v-else-if="error == 'invalid-interval'">Plage horaire invalide</div>
                            <div class="invalid-feedback d-flex" v-else-if="error == 'overlapping'">Conflit entre plusieurs plages horaires</div>
                        </div>
                    </div>
                    <div class="input-group d-flex flex-column mt-3">
                        <div class="d-flex flex-column col-12">
                            <label>Au :</label>
                            <div class="d-flex justify-content-between flex-wrap">
                                <input type="date" :disabled="disabled || line.code" @blur="$emit('lineUpdated', JSON.stringify(line))" :value="line.to && line.to.toISODate()" @input="setToDate($event)" class="form-control col-12 col-md-5" :class="{'is-invalid': error }">
                                <input type="time" :disabled="disabled || line.code" @blur="$emit('lineUpdated', JSON.stringify(line))" :value="line.to && line.to.toISOTime({includeOffset: false, suppressMilliseconds: true, extendedZone: false, suppressSeconds: true})" @input="setToTime($event)" class="form-control col-12 col-md-5" :class="{'is-invalid': error }">
                            </div>
                            <div class="invalid-feedback d-flex" v-if="error == 'invalid-to'">Date invalide</div>
                            <div class="invalid-feedback d-flex" v-else-if="error == 'invalid-interval'">Plage horaire invalide</div>
                            <div class="invalid-feedback d-flex" v-else-if="error == 'overlapping'">Conflit entre plusieurs plages horaires</div>
                        </div>
                    </div>
                    <div class="input-group d-flex flex-column mt-3">
                        <div class="d-flex flex-column col-12">
                            <label>Absence :</label>
                                <select
                                class="form-control js-hide-neighbours"
                                :disabled="disabled"
                                v-model="lineCode"
                            >
                                <option value=""></option>
                                <template v-for="workLeaveType in workLeaveTypes">
                                    <option v-text="workLeaveType.name" :value="workLeaveType.code"></option>
                                </template>
                            </select>
                        </div>
                    </div>
                    <div class="invalid-feedback"></div>
                    <div class="d-flex justify-content-around py-3 ">
                        <button type="button" title="Supprimer" class="btn btn-round btn-dugong" @click="deleteLine()" :disabled="disabled">
                        Supprimer
                        </button>
                    </div>
                </div>
                <div colspan="12" class="d-flex justify-content-end" :class="{'line-through': line.code}">
                    <strong>Total : <span v-text="timeSheetLineDuration.hours" />h<span v-text="String(timeSheetLineDuration.minutes).padStart(2, '0')" /></strong>
                </div>
            </div>
        </div>
    </tr>
</template>

<script>
    import { DateTime, Interval } from 'luxon';

    export default {
        name: "TimeSheetLine",
        data() {
            return {
                readonly: true,
                lineCode: null,
            };
        },
        props: {
            disabled: {
                type: Boolean,
                default: false,
            },
            line: {
                type: Object,
                default() {
                    return {
                        from: null,
                        to: null,
                        selected: false
                    };
                }
            },
            differFromPlanningFrom: {
                type: Boolean,
                default: false,
            },
            differFromPlanningTo: {
                type: Boolean,
                default: false,
            },
            isDeleted: {
                type: Boolean,
                default: false,
            },
            workLeaveTypes: {
                type: Array,
                default() {
                    return [];
                },
            },
            workLeaveType: {
                type: String,
                default:'',
            },
        },
        created() {
            if (this.line.deleted !== null || this.line.code !== null) {
                this.isDeleted = true;
            }

            if (this.line.differ == 'both') {
                this.differFromPlanningFrom = true;
                return this.differFromPlanningTo = true;
            }

            if (this.line.differ == 'from') {
                return this.differFromPlanningFrom = true;
            }

            if (this.line.differ == 'to') {
                return this.differFromPlanningTo = true;
            }
        },
        computed: {
            error() {
                if (!this.line.from && !this.line.from.isValid) {
                    return 'invalid-from';
                }

                if (!this.line.to && !this.line.to.isValid) {
                    return 'invalid-to';
                }

                let interval = Interval.fromDateTimes(this.line.from, this.line.to);

                if (!interval.isValid ) {
                    return 'invalid-interval';
                }

                let overlapping = this.$parent
                    .timetable
                    .filter(function (line) {
                        if (line.deleted) {
                            return;
                        }
                        let lineInterval = Interval.fromDateTimes(line.from, line.to);

                        return interval.overlaps(lineInterval);
                    })

                if (overlapping.length > 1) {
                    return 'overlapping';
                }

                return null;
            },

            timeSheetLineDuration() {
                let duration = this.line.duration()

                return {
                    'hours': duration.hours,
                    'minutes': String(duration.minutes).padStart(2, '0')
                };
            },

            workleave() {
                if (this.line.code) {
                    const filteredLeaveTypes = Object.values(this.workLeaveTypes).filter(type => type.code === this.line.code);
                    return(filteredLeaveTypes[0].name)
                }
            },
        },
        methods: {
            updateWorkLeaveCode(workLeaveType) {
              this.line.code = workLeaveType;
            },
            deleteLine() {
                this.readonly = true;
                this.$nextTick(() => {
                    this.$emit('dropLine');
                });
            },
            formatDayOfWeek(date) {
              return DateTime.fromISO(date).setLocale('fr').toFormat('ccc dd MMM');
            },
            editLine() {
                if (!this.error && !this.disabled && !this.isDeleted) {
                    this.readonly = !this.readonly;
                }
                this.$emit('sortTimetable');
            },
            setFromDate($event) {
                let date = $event.target.value.split('-');

                this.line.from = this.line.from.set({
                    year: date[0],
                    month: date[1],
                    day: date[2],
                });
            },
            setFromTime($event) {
                let date = $event.target.value.split(':');

                this.line.from = this.line.from.set({
                    hours: date[0],
                    minutes: date[1],
                });
            },
            setToDate($event) {
                let date = $event.target.value.split('-');

                this.line.to = this.line.to.set({
                    year: date[0],
                    month: date[1],
                    day: date[2],
                });
            },
            setToTime($event) {
                let date = $event.target.value.split(':');

                this.line.to = this.line.to.set({
                    hours: date[0],
                    minutes: date[1],
                });
            }
        },
        watch: {
            workLeaveType(workLeaveType) {
              this.updateWorkLeaveCode(workLeaveType);
            },
            lineCode(newValue) {
              if (newValue === "") {
                this.lineCode = null;
              }
              this.line.code = this.lineCode;
            }
        },

    }
</script>

<style scoped lang="scss">
    .color-fuel-yellow {
        color: #d48f31;
    }

    .line-through {
        text-decoration: line-through;
    }
</style>
